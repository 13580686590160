import React, { Component } from 'react'
import { Menu } from 'antd'
import { Link } from "gatsby"
import './Menu.css'


const { SubMenu } = Menu;
const linkStyles ={
    fontSize: '15px',
    fontWeight: 'bold',
}

export default class MainMenu extends Component {
    render() {
      return (
    <Menu mode={this.props.mode} className='menu'>
        <Menu.Item className='menuItem' style={{borderBottom:'none'}}>            
            <Link className = 'menuLink' to='/' style={linkStyles}>主頁</Link>
        </Menu.Item>
        <SubMenu title="關於我們" className='menu' style={{borderBottom:'none'}}>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/AboutUs' style={linkStyles}>教會簡介/歷史</Link>
            </Menu.Item>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/Principals' style={linkStyles}>教會信條</Link>
            </Menu.Item>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/Staff' style={linkStyles}>牧職同工</Link>
            </Menu.Item>
        </SubMenu>
        <SubMenu title="主日崇拜" style={{borderBottom:'none'}}>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/SundayWorshipInfo' style={linkStyles}>主日信息</Link>
            </Menu.Item>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/AdultSundaySchool' style={linkStyles}>成人主日學</Link>
            </Menu.Item>
            {/* <Menu.Item>
                <Link to='/YouthActivities'>青少年活动</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to='/ChildrenActivities'>幼儿/儿童活动</Link>
            </Menu.Item>
            */}
        </SubMenu>
        <SubMenu title="事工簡介" className='menu' style={{borderBottom:'none'}}>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/FellowshipGroup' style={linkStyles}>小組</Link>
            </Menu.Item>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/ChildrenGroup' style={linkStyles}>兒童</Link>
            </Menu.Item>
            {/* <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/StudentGroup' style={linkStyles}>学生</Link>
            </Menu.Item>
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/Mission' style={linkStyles}>宣教</Link>
            </Menu.Item> */}
            <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
                <Link className = 'menuLink' to='/WestPlantChurch' style={linkStyles}>西區植堂</Link>
            </Menu.Item>                
        </SubMenu>
        <Menu.Item className='menuItem' style={{borderBottom:'none'}}>            
            <Link className = 'menuLink' to='/GivingInfo' style={linkStyles}>線上奉獻</Link>
        </Menu.Item>
        <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
            <Link className = 'menuLink' to='/IamNew' style={linkStyles}>新人報道</Link>
        </Menu.Item>
        {/* <Menu.Item className='menuItem' style={{borderBottom:'none'}}>
            <Link className = 'menuLink' to='/Feedback' style={linkStyles}>反馈</Link>
        </Menu.Item> */}
    </Menu>
      )
    }
}
