import React from 'react'
import { Col, Row, Form, Input, Button } from 'antd'
import { Link } from "gatsby"
import './GlobalFooter.css'

export default () =>{
    return(
        <div className='globalFooterContainer'>
            <div className='globalFooterDesktop'>
                <div className='globalFooterSection1'>
                    <Row>
                        <Col span={6} offset={4}>
                            <h2>訂閱教會周報</h2>
                            <Form layout={"inline"}>
                                <Form.Item>
                                    <Input placeholder="输入邮箱"/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{backgroud: "#fcbe32"}}>
                                    訂閱
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={2} offset={1}>
                            <h3>教會介紹</h3>
                            <p><Link to='/AboutUs' style={{color:'white'}}>教會歷史</Link></p>
                            <p><Link to='/Principals' style={{color:'white'}}>教會信條</Link></p>
                            <p><Link to='/Staff' style={{color:'white'}}>牧職同工</Link></p>
                            <p><a style={{color:'white'}} href='https://goo.gl/maps/oHzdpbXQdueg6SoW8'>教會地址</a></p>
                        </Col>
                        <Col span={2} offset={1}>
                            <h3>主日崇拜</h3>
                            <p><Link to='/SundayWorshipInfo' style={{color:'white'}}>線上崇拜</Link></p>
                            <p><Link to='/AdultSundaySchool' style={{color:'white'}}>成人主日學</Link></p>
                        </Col>
                        <Col span={3} offset={1}>
                            <h3>疫情相關</h3>
                            <p><a style={{color:'white'}} href='https://www.michigan.gov/coronavirus/0,9753,7-406-98178_98541_98545---,00.html'>新冠肺炎（COVID-19）指南</a></p>
                            <p><a style={{color:'white'}} href='https://www.michigan.gov/Coronavirus'>Michigan COVID Guideline Page </a></p>
                        </Col>
                    </Row>
                </div>
                 <div className='globalFooterSection2'>
                    <Row>
                        <Col span={14} offset={4}>
                            <p>COPYRIGHT<a style={{color:'white'}} href='https://goo.gl/maps/oHzdpbXQdueg6SoW8'> 中華福音教會 35301 Five Mile Rd, Livonia, MI 48154</a></p>
                            <p>電話： (734) 464-7077 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 郵箱：cgcdetroit@gmail.com &nbsp;&nbsp;&nbsp;</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='globalFooterMobile'>
                <div className='globalFooterMobileSection1'>
                    <Row>
                        <Col span={20} offset={2}>
                            <h2>訂閱教會周報</h2>
                            <Form layout={"inline"}>
                                <Form.Item>
                                    <Input placeholder="輸入郵箱"/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{backgroud: "#fcbe32"}}>
                                    訂閱
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                    <Row className='globalFooterMobileSection2'>
                        <Col span={20} offset={2}>
                            <h3>教會介紹</h3>
                            <p><Link to='/AboutUs' style={{color:'white'}}>教會歷史</Link></p>
                            <p><Link to='/Principals' style={{color:'white'}}>教會信條</Link></p>
                            <p><Link to='/Staff' style={{color:'white'}}>牧職同工</Link></p>
                            <p><a style={{color:'white'}} href='https://goo.gl/maps/oHzdpbXQdueg6SoW8'>教會地址</a></p>
                        </Col>
                    </Row>
                    <Row className='globalFooterMobileSection2'>
                        <Col span={20} offset={2}>
                            <h3>主日崇拜</h3>
                            <p><Link to='/SundayWorshipInfo' style={{color:'white'}}>線上崇拜</Link></p>
                            <p><Link to='/AdultSundaySchool' style={{color:'white'}}>成人主日學</Link></p>
                        </Col>
                    </Row>
                    <Row className='globalFooterMobileSection2'>
                        <Col span={20} offset={2}>
                            <h3>疫情相關</h3>
                            <p><a style={{color:'white'}} href='https://www.michigan.gov/coronavirus/0,9753,7-406-98178_98541_98545---,00.html'>新冠肺炎（COVID-19）指南</a></p>
                            <p><a style={{color:'white'}} href='https://www.michigan.gov/Coronavirus'>Michigan COVID Guideline Page </a></p>
                        </Col>
                    </Row>
                </div>
                <div className='globalFooterMobileSection3'>
                <Row>
                    <Col span={20} offset={2}>
                    <p>COPYRIGHT &#169; 2021 中華福音教會 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 地址：<a style={{color:'white'}} href='https://goo.gl/maps/oHzdpbXQdueg6SoW8'>35301 Five Mile Rd, Livonia, MI 48154</a></p>
                    <p>電話：(734) 464-7077 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 郵箱：cgcdetroit@gmail.com &nbsp;&nbsp;&nbsp;</p>
                    </Col>
                </Row>
                </div>
            </div>
        </div>
    )
}