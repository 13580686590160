import React, { Component } from 'react'
import DesktopMenu from './Menu'
import Logo from "../../images/homepage_images/logo.png"
import { Drawer, Button, Col, Row} from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { Link } from "gatsby"
import './NavBar.css'


export default class Navbar extends Component {
  state = {
    visible: false
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    return (
      <div className="navbar">
        <Row>
          <Col span={4} offset={4}>
            <div className="desk_menu_logo">
              <Link to='/'>
                <img src={Logo} alt="logo"></img>
              </Link>
            </div>
            <div className="mobile_menu_logo">
              <Link to='/'>
                <img src={Logo} alt='logo_mobile'></img>
              </Link>
            </div>
          </Col>
          <Col span={9} offset={3}>
            <div className="menu_container">
              <div className="menu_desktop">
                <DesktopMenu mode="horizontal" />
              </div>
          <Button
            className="menu_mobile-button"
            type="primary"
            onClick={this.showDrawer}
          >
			  <MenuOutlined style={{fontSize:'25px',color:'white'}} />
          </Button>
          <Drawer
            title="Welcome to CGC Web"
            placement="right"
            className="menu_drawer"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <DesktopMenu mode="inline" />
          </Drawer>
        </div>
        </Col>
        </Row>
      </div>
    )
  }
}